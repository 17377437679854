import { createContext } from "react";

export const Store = createContext();

export const initialState = {
  token: {
    value:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1ODk4MjU2NTcsIm5iZiI6MTU4OTgyNTY1NywianRpIjoiNzNlZjM1OWEtNzRhMC00Y2M3LTkwNzEtMDBmYTgxYWFkNTBjIiwiaWRlbnRpdHkiOnsicmVxdWVzdF9pZCI6IjVlYzJkMDM2Y2JmNDE5OGUzYjk3ZDZmZiIsImNsaWVudF9pZCI6IjVkOWFiNjY1ZDMzMGVmMzAxMDE2Mzk1MCIsInJvbGUiOiJjYW5fc2VuZF9maXJzdF9saXZlbmVzc19mcmFtZSIsImNlcCI6IjEzNzIwMDAwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.0uI6gmRE2H8Y1BdpanhJTgov3m6i760u6An5xzGsla0",
    alternate: "",
  },
  home: {
    cpf: "",
    phone: "",
    email: "",
  },
  terms: {
    agree: false,
  },
  address: {
    cep: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    uf: "",
  },
  docs: {
    type: "",
  },
  callback: {
    url: "",
  },
};

export const LoginStore = createContext();

export const loginInitialState = {
  username: "",
  password: "",
  token: "",
};
